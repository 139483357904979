<template>
	<div class="card-record">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>二次供水泵房管理 门禁记录</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>资产管理</li>
						<li>/</li>
						<li>泵房资产</li>
					</ul>
				</div>
			</div>
			<div>
				<el-select
					size="medium"
					class="mr-2 mb-2"
					v-model="activeAuthStatus"
					placeholder="鉴权状态"
				>
					<el-option
						v-for="item in authStatus"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>

				<el-select 	v-model="activeAuthStatus" size="medium" class="mr-2 mb-2" placeholder="所在小区">
					<el-option
						v-for="item in authStatus"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
				<el-select	v-model="activeAuthStatus" size="medium" class="mr-2 mb-2" placeholder="持卡人">
					<el-option
						v-for="item in authStatus"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>

				<el-select	v-model="activeAuthStatus" size="medium" class="mr-2 mb-2" placeholder="泵房">
					<el-option
						v-for="item in authStatus"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>

				<el-date-picker
					class="mr-2 mb-2"
					size="medium"
					value-format="timestamp"
					v-model="dateRange"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:default-time="['00:00:00', '23:59:59']"
				>
				</el-date-picker>

				<el-button
					icon="el-icon-search"
					type="primary"
					class="mr-2 mb-2"
					size="medium"
					>查询</el-button
				>
			</div>

			<div class="my-2">
				<vxe-table
					class="vxe-table-wrap"
					cell-class-name="vxe-table-cell "
					size="mini"
					height="700px"
					stripe
					highlight-hover-row
					border
					show-overflow
					keep-source
					auto-resize
					:data="[]"
				>
					<vxe-table-column
						field="name"
						title="小区"
						header-align="center"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="province"
						title="泵房"
						header-align="center"
						align="left"
						width="90"
						cell-type="string"
					>
					</vxe-table-column>
					<vxe-table-column
						field="city"
						title="持卡人"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="district"
						title="状态"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="area"
						title="IC卡号"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="addr"
						title="状态"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>

					<vxe-table-column
						field="addr"
						title="上报时间"
						header-align="left"
						align="left"
						cell-type="string"
					>
					</vxe-table-column>
				</vxe-table>

				<div>
					<el-pagination
						background
						layout="prev, pager, next, sizes,jumper,total"
						:total="1000"
					>
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../components/sidebar.vue'
import headerTop from '../components/header.vue'
export default {
	name: 'card-record',
	components: {
		// Com,
		sidebar,
		headerTop,
	},
	data() {
		return {
			activeAuthStatus: '0',
			authStatus: [
				{ label: '全部状态', value: '0' },
				{ label: '鉴权成功', value: '1' },
				{ label: '鉴权失败', value: '2' },
			],
			dateRange: [],

			page5: {
				currentPage: 1,
				pageSize: 10,
				totalResult: 300,
			},
		}
	},
}
</script>

<style lang="scss" scoped>
.card-record {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
    ::v-deep {
			.vxe-table--body-wrapper {
				color: #fff;
				border: 1px solid rgba(255, 255, 255, 0.55);
				.vxe-cell {
					border-bottom: 1px solid rgba(255, 255, 255, 0.55);
					padding: 2px 5px;
					background: rgba(0, 0, 0, 0.35);
				}
			}
			.vxe-table--header-wrapper {
				border: 1px solid rgba(255, 255, 255, 0.55);
        border-bottom: none;
				background: rgba(0, 0, 0, 0.75);
				.vxe-cell {
					overflow: hidden;
					color: #fff;
					padding: 5px;
					text-align: center;
				}
			}
			.vxe-table--render-default .vxe-table--empty-block {
				display: none;
				visibility: hidden;
			}
			.vxe-table--empty-content {
				display: none;
			}
			.vxe-table--empty-placeholder {
				border: 1px solid rgba(255, 255, 255, 0.55);
				border-top: none;
				display: none;
			}
			.el-pagination__jump,
			.el-pagination__total {
				color: #fff;
				margin: 0 10px;
			}
			.el-pagination {
				text-align: right;
				margin-top: 20px;
			}
			.el-tabs--border-card,
			.el-tabs--border-card > .el-tabs__header {
				background: transparent;
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item {
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
				color: #d12;
			}
			.el-tree {
				color: #fff;
				background: rgba(0, 0, 0, 0.35);
			}
			.el-tree-node__content:hover {
				background-color: rgba(0, 0, 0, 0.75);
			}
			.el-tree-node:focus > .el-tree-node__content {
				background-color: rgba(0, 0, 0, 0.75);
			}
		}
	}
}
</style>
