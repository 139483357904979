<template>
	<div class="top">
		<div class="header">
			<div class="header-left">
				<i
					class="hl"
					@click="newMessage()"
					@mouseenter="mouseenterMessage = 1"
					@mouseleave="mouseenterMessage = 0"
				>
					<img src="@/assets/images/energyManage/message.png" alt="" />
					<i class="count">5</i>
				</i>
				<span class="name" :class="{ showName: mouseenterMessage === 1 }"
					>实时消息</span
				>
				<i
					class="hl"
					@click="warning()"
					@mouseenter="mouseenterWarning = 1"
					@mouseleave="mouseenterWarning = 0"
				>
					<img src="@/assets/images/energyManage/updates.png" alt="" />
					<i class="count">5</i>
				</i>
				<span class="name" :class="{ showName: mouseenterWarning === 1 }"
					>警告信息</span
				>
				<input type="text" class="search" />
			</div>
			<div class="header-right">{{ time }}</div>
		</div>
		<div class="listview" v-if="showMessage">
			<div class="listview-top">
				<span>新消息</span>
				<span>×</span>
			</div>
			<div class="media" v-for="i in 5" :key="i">
				<div class="pull-left">
					<img width="40" src="@/assets/images/energyManage/1.png" alt="" />
				</div>
				<div class="media-body">
					<small class="text-muted">调度班长 - 2分钟前</small><br />
					<a class="t-overflow" href="#"
						>**公司系统数据采集异常，请工作人员检查。</a
					>
				</div>
			</div>
			<div class="listview-footer">
				<span>查看所有</span>
			</div>
		</div>
		<div class="listview" v-if="showWarning">
			<div class="listview-top">
				<span>实时警告</span>
				<span>×</span>
			</div>
			<div class="media" v-for="i in 5" :key="i">
				<div class="pull-left">
					<img width="40" src="@/assets/images/energyManage/1.png" alt="" />
				</div>
				<div class="media-body">
					<small class="text-muted">调度班长 - 2分钟前</small><br />
					<a class="t-overflow" href="#"
						>**公司数据采集异常，请工作人员检查。</a
					>
				</div>
			</div>
			<div class="listview-footer">
				<span>查看所有</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mouseenterMessage: 0,
			mouseenterWarning: 0,
			showWarning: false, //实时消息
			showMessage: false, //实时警告
			lastSuccessTime: new Date(),
			time: '',
		}
	},
	created() {
		this.dateFormat()
	},
	methods: {
		newMessage() {
			this.showMessage = !this.showMessage
			if (this.showWarning) {
				this.showMessage = true
				this.showWarning = false
			}
		},
		warning() {
			this.showWarning = !this.showWarning
			if (this.showMessage) {
				this.showWarning = true
				this.showMessage = false
			}
		},
		//实时时间
		dateFormat() {
			this.date = new Date()
			let hours =
				this.date.getHours() < 10
					? '0' + this.date.getHours()
					: this.date.getHours()
			let minutes =
				this.date.getMinutes() < 10
					? '0' + this.date.getMinutes()
					: this.date.getMinutes()
			let seconds =
				this.date.getSeconds() < 10
					? '0' + this.date.getSeconds()
					: this.date.getSeconds()
			this.time = hours + ':' + minutes + ':' + seconds
		},
		dataDestroy() {
			if (this.timer) {
				clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
			}
		},
		dateShow() {
			this.timer = setInterval(() => {
				this.dateFormat()
			}, 1000)
		},
	},
	mounted() {
		this.dateShow()
	},
}
</script>

<style lang="scss" scoped>
.top {
	.header {
		height: 50px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		display: flex;
		justify-content: space-between;
		.header-left {
			height: 50px;
			width: 90%;
			display: flex;
			.count {
				background: #f00000;
				font-size: 11px;
				position: absolute;
				top: 0;
				right: -10px;
				text-align: center;
				padding: 2px 3px 3px;
				border-radius: 2px !important;
				box-shadow: 0 0 5px rgb(0 0 0 / 6%);
				margin: 7px 0 0 -13px;
				font-style: normal;
				line-height: 100%;
				-webkit-animation-name: bounceIn;
				animation-name: bounceIn;
				font-family: open-sans-regular;
				box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
				color: #fff;
			}
			.name {
				color: #fff;
				font-size: 13px;
				width: 80px;
				display: none;
				margin-top: 20px;
			}
			.showName {
				display: block;
			}
			.hl {
				position: relative;
				display: inline-block;
				margin-right: 20px;
				line-height: 50px;
				cursor: pointer;
			}
			.search {
				height: 40px;
				border: 0;
				width: 95%;
				font-size: 16px;
				background-color: transparent;
				background-image: url(~@/assets/images/energyManage/search.png);
				background-repeat: no-repeat;
				background-position: 0 9px;
				padding-left: 32px;
				margin-top: 5px;
				color: #fff;
				text-shadow: 0 0 3px rgb(255 255 255 / 40%);
				border: none;
			}
		}
		.header-right {
			font-size: 20px;
			padding-top: 10px;
			margin-right: 4px;
			color: #fff;
		}
	}
	.listview {
		font-size: 14px;
		color: #fff;
		background: rgba(0, 0, 0, 0.35);
		.listview-top {
			padding: 5px 10px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		}
		.media {
			display: flex;
			padding: 5px 10px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			.pull-left {
				margin-right: 10px;
			}
			.media-body {
				.text-muted {
					color: #bbb;
				}
				.t-overflow {
					color: #fff;
				}
				.t-overflow:hover {
					color: #ffd600;
				}
			}
		}
		.media:hover,
		.listview-top:hover,
		.listview-footer:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
		.listview-footer {
			color: #fff;
			text-align: center;
		}
	}
}
</style>
