<template>
	<div class="sidebar">
		<div class="top">
			<span @click="$router.push('/energy-home')"
				><i class="el-icon-menu"></i
			></span>
			<span>二次供水能效管理平台</span>
		</div>
		<div class="navbar">
			<el-menu
				default-active="2"
				class="el-menu-vertical-demo"
				background-color="rgba(0, 0, 0, 0.55)"
			>
				<el-menu-item index="2" @click="$router.push('/energy-home')">
					<i class="el-icon-wallet"></i>
					<span slot="title">运行监测</span>
				</el-menu-item>
				<el-menu-item index="2" @click="$router.push('/keyInfo')">
					<i class="el-icon-box"></i>
					<span slot="title">KPI</span>
				</el-menu-item>
				<el-menu-item index="3" @click="$router.push('/mstp-map')">
					<i class="el-icon-s-data"></i>
					<span slot="title">全息图概括</span>
				</el-menu-item>
				<el-menu-item index="4" @click="$router.push('/efficiencyAnalysis')">
					<i class="el-icon-finished"></i>
					<span slot="title">能耗统计</span>
				</el-menu-item>
				<el-menu-item index="5" @click="$router.push('/dianfei')">
					<i class="el-icon-money"></i>
					<span slot="title">电费管理</span>
				</el-menu-item>
				<el-menu-item index="6" @click="$router.push('/deviceManager')">
					<i class="el-icon-suitcase"></i>
					<span slot="title">设备管理</span>
				</el-menu-item>
				<el-menu-item index="7" @click="$router.push('/energy_consumption')">
					<i class="el-icon-date"></i>
					<span slot="title">报表管理</span>
				</el-menu-item>
				<el-menu-item index="8" @click="$router.push('/userMng')">
					<i class="el-icon-user"></i>
					<span slot="title">用户管理</span>
				</el-menu-item>
				<el-menu-item index="9" @click="$router.push('/pumpRoomNav')">
					<i class="el-icon-location"></i>
					<span slot="title">泵房导航</span>
				</el-menu-item>
				<el-menu-item index="10" @click="$router.push('/systemSettings')">
					<i class="el-icon-setting"></i>
					<span slot="title">系统设置</span>
				</el-menu-item>
				<el-submenu index="11">
					<template slot="title">
						<i class="el-icon-coin"></i>
						<span>资产管理</span>
					</template>
					<el-menu-item @click="$router.push('/estate/community')" index="11-1"
						>小区资产</el-menu-item
					>
					<el-menu-item @click="$router.push('/estate/pump-house')" index="11-2"
						>泵房资产</el-menu-item
					>
					<el-menu-item @click="$router.push('/estate/life-time')" index="11-2"
						>生命周期管理</el-menu-item
					>
				</el-submenu>
				<el-submenu index="12">
					<template slot="title">
						<i class="el-icon-lock"></i>
						<span>门禁管理</span>
					</template>
					<el-menu-item @click="$router.push('/door/ic-card')" index="12-1"
						>IC卡管理</el-menu-item
					>
					<el-menu-item @click="$router.push('/door/card-record')" index="12-2"
						>门禁记录</el-menu-item
					>
				</el-submenu>
        <el-menu-item index="13" @click="$router.push('/EstateAny')">
					<i class="el-icon-data-line"></i>
					<span slot="title">资产分析</span>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeShow: '',
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss">
.sidebar {
	color: #fff;
	flex: 1;
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.55);
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	.top {
		line-height: 50px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		display: flex;
		span:nth-child(1) {
			width: 47px;
			height: 50px;
			display: inline-block;
			background: rgba(0, 0, 0);
			text-align: center;
			cursor: pointer;
			font-size: 25px;
		}
		span:nth-child(2) {
			height: 50px;
			text-align: center;
			font-size: 16px;
			background: rgba(0, 0, 0, 0.23);
			display: inline-block;
			line-height: 50px;
			cursor: pointer;
		}
		span:nth-child(2):hover {
			color: #ffd600;
		}
	}
	.navbar {
		::v-deep {
			.el-menu-item.is-active,
			.el-submenu__title,
			.el-menu-item {
				color: rgba(255, 255, 255, 0.55);
			}
		}
	}
}
</style>
